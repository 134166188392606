import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import posthog from "posthog-js";

import { ManualSolutionRequest } from "api/resources/ManualSolution";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Loader } from "components/common/Loader";

import SampleFile from "./solution_template.xlsx";
import { getSolutionsOptions } from "slices/solutions/solutionsSlice";
import { shallowEqual, useSelector } from "react-redux";
import { getIsAdmin } from "slices/userSlice";

export const AddSolution = ({
  as = Button,
  recovery_query_uid,
  base_scenario_uid,
  children,
  onSuccess,
}) => {
  const Component = as;
  const [show, setShow] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const isAdmin = useSelector(getIsAdmin, shallowEqual);
  const solution_options = useSelector(
    getSolutionsOptions(isAdmin),
    shallowEqual
  );
  const [selected_base_uid, setSelectedSolutionUid] =
    useState(base_scenario_uid);

  const [loading, setLoading] = useState(false);
  const fileInput = useRef(null);

  const handleClose = () => {
    setLoading(false);
    setFile();
    setError();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    setSelectedSolutionUid(base_scenario_uid);
  }, [base_scenario_uid]);

  const uploadSolution = async () => {
    if (!file) {
      toast.error("Please select a file");
      return;
    }
    setLoading(true);
    setError();
    posthog.capture('recover_upload_manual_solution')
    try {
      const request = new ManualSolutionRequest();
      await request.upload(recovery_query_uid, selected_base_uid, file);
      setLoading(false);
      setShow(false);
      onSuccess();
      setFile();
      toast.success("Solution upload successful");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setFile();
    }
  };

  const options = [
    { label: "base", value: base_scenario_uid },
    ...solution_options,
  ];

  return (
    <>
      <Component variant="primary" onClick={handleShow}>
        {children}
      </Component>

      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        animation={false}
        restoreFocus={false}
      >
        <Loader show={loading} />
        <Modal.Header closeButton>
          <Modal.Title>Add Solution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <InputGroup as={Col}>
              <Select
                classNamePrefix="Select"
                styles={{ container: () => ({ padding: 0 }) }}
                className="form-control"
                options={options}
                value={options.find(
                  (option) => option.value === selected_base_uid
                )}
                onChange={(option) => setSelectedSolutionUid(option.value)}
              />
            </InputGroup>
          </Row>
          <br />
          <Row>
            <Col>
              <InputGroup>
                <InputGroup.Prepend>
                  <Button onClick={() => fileInput.current.click()}>
                    Browse&hellip;
                    <Form.Control
                      ref={fileInput}
                      style={{ display: "none" }}
                      type="file"
                      onChange={() => setFile(fileInput.current.files[0])}
                    />
                  </Button>
                </InputGroup.Prepend>
                <Form.Control
                  readOnly
                  type="text"
                  defaultValue={file && file.name}
                />
              </InputGroup>
            </Col>
          </Row>
          {error && (
            <Row>
              <Col>
                <Alert style={{ marginTop: "20px" }} variant="danger">
                  {error?.split("\n").map((error_line) => (
                    <p>{error_line}</p>
                  ))}
                </Alert>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Link
            as={Button}
            to={SampleFile}
            target="_blank"
            download="solution_template.xlsx"
          >
            Download
          </Link>
          <Button variant="primary" onClick={uploadSolution}>
            Upload Solution
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
