import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Button, Form, Nav } from "react-bootstrap";

import styles from "./index.module.css";
let cx = classNames.bind(styles);

export const RecoverNav = ({
  onRecoverWithUpdate,
  onRecoverWithoutUpdate,
  is_tt_active,
}) => {
  const [prevent_refresh, setPreventRefresh] = useState(false);

  useEffect(() => {
    setPreventRefresh(is_tt_active);
  }, [is_tt_active]);

  const onRecover = () => {
    if (prevent_refresh) {
      onRecoverWithoutUpdate();
    } else {
      onRecoverWithUpdate();
    }
  };

  return (
    <Nav>
      <Nav.Item>
        <Button
          size="lg"
          className={cx(styles.SubmitButtons, styles.Recover)}
          onClick={() => onRecover()}
        >
          Recover
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Form.Check
          type="checkbox"
          style={{ lineHeight: "48px", marginLeft: "10px" }}
        >
          <Form.Check.Input
            style={{
              width: "20px",
              position: "relative",
            }}
            type="checkbox"
            isValid
            checked={prevent_refresh}
            onChange={(e) => setPreventRefresh(e.target.checked)}
          />
          <Form.Check.Label
            style={{
              color: "white",
            }}
          >{`Prevent refresh before recover`}</Form.Check.Label>
        </Form.Check>
      </Nav.Item>
    </Nav>
  );
};
