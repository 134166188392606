import React, { Suspense, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import autoBind from "auto-bind";
import { push } from "connected-react-router";
import { Link, NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Button, Form, Nav, Navbar } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Octicon from "react-octicon";

import { Auth } from "api";

import { fetchBasics } from "slices";
import userSlice from "slices/userSlice";
import { updateUser } from "slices/usersSlice";

import { Builder } from "pages/Builder";
import { Recover } from "pages/Recover";
import { SolutionRedirect } from "pages/Solution";
import { Login } from "pages/Login";
import { Users } from "pages/Users";
import { Help } from "pages/Help";
import { Reset } from "pages/Reset";
import { Historic } from "pages/Historic";
import { NextDayRedirect } from "pages/NextDay";
import { organizations, getOptionByValue } from "pages/Users/options";
import { Queues } from "pages/Queues";

import { Logo } from "components/common/Logo";
import Select from "react-select";
import rolesSlice from "slices/rolesSlice";
import { deepMemo } from "utils";

const HandoverReport = React.lazy(() => import("pages/Reports/HandoverReport"));
const OCCBriefingReport = React.lazy(() =>
  import("pages/Reports/OCCBriefingReport")
);
const DynamicTargetsReport = React.lazy(() =>
  import("pages/Reports/DynamicTargetsReport")
);
const Config = React.lazy(() => import("pages/Settings/components/Config"));
const DynamicTargetsSettings = React.lazy(() =>
  import("pages/Settings/components/DynamicTargetsSettings")
);
const TargetsSettings = React.lazy(() =>
  import("pages/Settings/components/TargetsSettings")
);

function PrivateRoute({ component: Component, show, title, ...rest }) {
  useEffect(() => {
    document.title = title || "OCCam";
  }, [title]);
  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

const AuthButton = () => {
  const dispatch = useDispatch();
  return (
    <Button
      variant="info"
      onClick={() => {
        dispatch({ type: "USER_LOGOUT" });
        dispatch(push("/login"));
      }}
    >
      Logout <Octicon name="sign-out" />
    </Button>
  );
};

const MainNav = deepMemo(
  ({
    isAdmin,
    isManager,
    isUser,
    isMachine,
    userDisplay,
    isDocs,
    show_org_select,
    show_demo_select,
    user,
    onChangeOrganization,
    onChangeIsDemo,
  }) => {
    return (
      <React.Fragment>
        <Navbar
          collapseOnSelect
          expand="md"
          bg="dark"
          variant="dark"
          className={isDocs && "sticky-top"}
        >
          <Navbar.Brand as={Link} to="/builder" style={{ lineHeight: "30px" }}>
            <Logo width="30" height="30" className="d-inline-block align-top" />
            <span>OCCam</span>
          </Navbar.Brand>
          {Auth.isAuthenticated() && (
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          )}
          {Auth.isAuthenticated() && (
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                {(isAdmin || isManager || isUser) && (
                  <Nav.Link
                    target="_blank"
                    eventKey="10"
                    as={NavLink}
                    to="/reports"
                  >
                    Reports
                  </Nav.Link>
                )}
                {(isAdmin || isManager) && (
                  <Nav.Link eventKey="20" as={NavLink} to="/users">
                    Users
                  </Nav.Link>
                )}

                {(isAdmin || isManager) && (
                  <Nav.Link eventKey="40" as={NavLink} to="/settings">
                    Settings
                  </Nav.Link>
                )}
                {(isAdmin || isManager || isUser) && (
                  <Nav.Link eventKey="60" as={NavLink} to="/historic">
                    Historic
                  </Nav.Link>
                )}
                {(isAdmin || isManager || isUser) && (
                  <Nav.Link eventKey="50" as={NavLink} to="/help">
                    Help
                  </Nav.Link>
                )}
                {(isAdmin) && (
                  <Nav.Link eventKey="30" as={NavLink} to="/queues">
                    Queues
                  </Nav.Link>
                )}
              </Nav>
              <Nav>
                <Nav.Item>
                  <span
                    style={{
                      color: "rgba(255,255,255,.5)",
                      lineHeight: "38px",
                      marginRight: "10px",
                    }}
                  >
                    Hello <strong>{userDisplay}</strong>
                  </span>
                  {show_demo_select && (
                    <div
                      style={{
                        display: "inline-block",
                        margin: "0 10px",
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        style={{ textAlign: "center" }}
                        checked={user.is_demo}
                        onChange={(e) => onChangeIsDemo(e.target.checked)}
                      />
                    </div>
                  )}
                  {show_org_select && (
                    <div
                      style={{
                        display: "inline-block",
                        margin: "0 20px",
                        minWidth: "190px",
                      }}
                    >
                      <Select
                        classNamePrefix="Select"
                        value={
                          user.organization &&
                          getOptionByValue(organizations, user.organization)
                        }
                        options={organizations}
                        onChange={onChangeOrganization}
                      />
                    </div>
                  )}
                </Nav.Item>
                <AuthButton />
              </Nav>
            </Navbar.Collapse>
          )}
        </Navbar>
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/reset/:token" component={Reset} />
            <PrivateRoute
              path="/users"
              exact
              title="OCCam | Users board"
              component={Users}
              show={isAdmin || isManager}
            />
            <PrivateRoute
              title="OCCam | Query builder"
              path="/builder/:base_scenario_uid?"
              exact
              component={Builder}
              show={isAdmin || isManager || isUser}
            />
            <PrivateRoute
              title="OCCam | Query results"
              path="/recover/:uid/:solution_uid?"
              exact
              component={Recover}
              show={isAdmin || isManager}
            />
            <PrivateRoute
              title="OCCam | Next day query results"
              path="/next_day/:uid/:solution_uid?"
              exact
              component={Recover}
              show={isAdmin || isManager || isUser}
            />
            <PrivateRoute
              path="/plan_redirect/:query_uid/:solution_uid"
              exact
              component={NextDayRedirect}
              show={isAdmin || isManager || isUser}
            />
            <PrivateRoute
              path="/solution/:uid/:base_plan"
              exact
              component={SolutionRedirect}
              show={isAdmin || isManager || isUser}
            />
            <PrivateRoute
              title="OCCam | Help"
              path="/help"
              exact
              component={Help}
              show={isAdmin || isManager || isUser}
            />
            <PrivateRoute
              title="OCCam | Targets"
              path="/settings/targets"
              exact
              component={TargetsSettings}
              show={isAdmin || isManager}
            />
            <PrivateRoute
              title="OCCam | Dynamic Targets"
              path="/settings/dynamic_targets"
              exact
              component={DynamicTargetsSettings}
              show={isAdmin || isManager}
            />
            <PrivateRoute
              title="OCCam | Config"
              path="/settings/config"
              exact
              component={Config}
              show={isAdmin || isManager}
            />
            <PrivateRoute
              title="OCCam | Historic"
              path="/historic"
              exact
              component={Historic}
              show={isAdmin || isManager || isUser}
            />
            <PrivateRoute
              title="OCCam | Queues"
              path="/queues"
              exact
              component={Queues}
              show={isAdmin}
            />
            <Redirect from="/settings" to="/settings/targets" />
            <PrivateRoute
              title="OCCam | Shift handover report"
              path="/reports/handover"
              exact
              component={HandoverReport}
              show={isAdmin || isManager || isUser}
            />
            <PrivateRoute
              title="OCCam | OCC Briefing report"
              path="/reports/briefing"
              exact
              component={OCCBriefingReport}
              show={isAdmin || isManager || isUser}
            />
            <PrivateRoute
              title="OCCam | Dynamic Targets report"
              path="/reports/targets"
              exact
              component={DynamicTargetsReport}
              show={isAdmin || isManager || isUser}
            />
            <Redirect from="/reports" to="/reports/handover" />
            <Route
              path="/unauth"
              component={() => (
                <center>
                  <h1>Missing permisions</h1>
                </center>
              )}
            />
            <Redirect path="*" to="login" />
          </Switch>
        </Suspense>
        <ToastContainer />
        <ReactTooltip />
      </React.Fragment>
    );
  }
);

class AppComponent extends React.Component {
  constructor(props) {
    super(props);
    if (Auth.isAuthenticated()) {
      props.fetchBasics();
    }
    autoBind(this);
  }

  getAirlineDisplay(isAdmin, isDemo, organization, roles) {
    var airlineDisplay;
    if (isAdmin) {
      if (isDemo) {
        airlineDisplay = "(demo)";
      } else {
        airlineDisplay = `(${organization}) [${roles.join(", ")}]`;
      }
    } else {
      airlineDisplay = "";
    }
    return airlineDisplay;
  }

  onChangeOrganization(option) {
    this.props.updateUser({
      email: this.props.user.email,
      organization: option.value,
    });
  }

  onChangeIsDemo(value) {
    this.props.updateUser({
      email: this.props.user.email,
      is_demo: value,
    });
  }

  render() {
    const roles = (this.props.user && this.props.user.roles) || [];
    const isAdmin = roles.indexOf("admin") > -1;
    const isManager = roles.indexOf("manager") > -1;
    const isUser = roles.indexOf("user") > -1;
    const isMachine = roles.indexOf("machine") > -1;
    const airlineDisplay = this.getAirlineDisplay(isAdmin, this.props.user.is_demo, this.props.user.organization, roles);
    const userDisplay = this.props.user
      ? `${this.props.user.name || this.props.user.email} ${airlineDisplay}`
      : "";
    const isDocs = window.location.pathname?.indexOf("/docs") > -1;
    const show_org_select = isAdmin && !this.props.user.is_demo;
    const show_demo_select = isAdmin;

    return (
      <MainNav
        user={this.props.user}
        onChangeOrganization={this.onChangeOrganization}
        onChangeIsDemo={this.onChangeIsDemo}
        isAdmin={isAdmin}
        isManager={isManager}
        isUser={isUser}
        isMachine={isMachine}
        userDisplay={userDisplay}
        isDocs={isDocs}
        show_org_select={show_org_select}
        show_demo_select={show_demo_select}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: userSlice.selectors.getUser(state),
  roles: rolesSlice.selectors.getRoles(state),
});

export const App = connect(mapStateToProps, {
  fetchBasics,
  updateUser,
  clearUser: userSlice.actions.clearUser,
})(AppComponent);
